import * as React from 'react';
import { Plus, Upload, Grab, Filter, ArrowDown } from 'baseui/icon';
import svgIcons from '../src/svg';
import styled from '@emotion/styled';
import { isNonNullObject } from '@apollo/client/utilities';

const StyledIcon = styled.img`
  width: 24px;
  height: 16px;
  margin-bottom: 0px;
`;
// import { 
//   CardLinkDeckIcon, 
//   DashboardDeckIcon, 
//   ImageDeckIcon, 
//   InsertLinkDeckIcon, 
//   PhoneLinkDeckIcon, 
//   TableDeckIcon 
// } from '../src/svg';


// editorBtnOptions are the options available for left side of menu of card back editor
// for example Button
// the format of Button simple fields you can see below object
// {
//   name: 'URL Button',
//   display: 'URL Button',
//   id: 'button_1',
//   icon: <Plus></Plus>,
//   parent: 'option_5',
//   __typename: 'ButtonCardModule',
//   addInNewCommunication: true,
//   inputs: [
//     {
//       type: 'Input',
//       key: 'title',
//       dataType: 'String',
//       title: 'Button Text',
//       value: '',
//       placeholder: 'Enter Button Text',
//     },
//     {
//       type: 'Input',
//       key: 'url',
//       dataType: 'String',
//       title: 'Button URL',
//       value: '',
//       placeholder: 'Enter Button URL',
//     },
//   ],
// }

// __typename is type of module if you look button in graphql this __typename will make sense easily
// if addInNewCommunication is set to true this will appear in communication messages builder
// inputs is the array of inputs required for example button requires Button text and url so it has two inputs
// in some cases simple Input or Select type does not work means they have some complex structure like if you look below
// video gallery so for that instead of mapping with diff text select filepicker we have created seprated for that read about this for better understanding

// some input type requires afterProcessing. what i mean with after processing is for example if you look below Image Gallery there is one input file
// picker is used so once files are dropped we will receive array of files dropped but that is not something server wants so we have to manipulate
// that  instead of array of files this will be array of objects
// {
//   file_data: null,
//   file: el,
//   caption: '',
//   position: 0,
// }
// so this can be Achieved by after processor 

// Need help in adding new module
// First open Card back editor and see already written modules hope your new module will match with any of existing
// just take ref and add new object below and try running it instead of going deep into the code.

export const editorBtnOptionsHeader = [
  {
    id: 'header_1',
    name: 'Fullscreen Components',
    quesMarkText: 'Please note that all full screen components can only contain one component in the builder, no other components can be dragged into the interface as they are full screen app experiences.'
  }
]

export const editorBtnOptions = [
  {
    name: 'Card',
    display: 'Card',
    id: 'card_link_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'CardLinkButtonCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    // iconName: 'sim-card',
    builderIcon: svgIcons.BlackCardIcon,
    customIcon: svgIcons.CardLinkDeckIcon,
    description: 'A content collection that tells a story through text, multimedia and interactivty.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'CardPicker',
        key: 'redirect_card_id',
        dataType: 'ID',
        title: 'Card',
        value: '',
        placeholder: 'Select Card',
      },
      {
        type: 'FilePicker',
        key: 'icon',
        mutation: {
          key: 'icon_upload',
          dataType: 'Upload',
        },
        dataType: 'String',
        title: 'Button Icon',
        value: '',
        placeholder: 'Enter Button Text',
        multiple: false,
        accept: "image/*"
      },
      {
        type: 'Input',
        key: 'width',
        dataType: 'String',
        title: 'Width of Button (in %)',
        value: '',
        placeholder: 'Enter Button Width',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style',
        dataType: 'String',
        title: 'Custom Css',
        value: '',
        placeholder: '',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
  },
  {
    name: 'Website',
    display: 'Website',
    id: 'button_12',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'ButtonCardModule',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    // iconName: 'link',
    builderIcon: svgIcons.BlackWebsiteIcon,
    customIcon: svgIcons.InsertLinkDeckIcon,
    description: 'A link to an external website or web based application.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'url',
        dataType: 'String',
        title: 'Button URL',
        value: '',
        placeholder: 'Enter Button URL',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
  },
  {
    name: 'Meeting',
    display: 'Meeting (Advanced)',
    id: 'meeting_link_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    builderIcon: svgIcons.BlackMeetingIcon,
    __typename: 'MeetingLinkCardModule',
    description: 'Add Zoom meeting',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Meeting Title',
      },
      {
        type: 'Input',
        key: 'meeting_link',
        dataType: 'String',
        title: 'Meeting link:',
        value: '',
        placeholder: 'Enter Meeting URL',
      },
    ],
  },
  {
    name: 'Data chip',
    display: 'Data chip',
    id: 'datachip_card_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_1',
    builderIcon: svgIcons.BlackMeetingIcon,
    customIcon: svgIcons.BlackMeetingIcon,
    addInHomeComponent: true,
    __typename: 'DatachipCardModule',
    description: 'A small content container which can include text, numbers and links.',
    inputs: [
      {
        type: 'Input',
        key: 'data_label',
        dataType: 'String',
        title: 'Data Label',
        value: '',
        placeholder: 'Enter Data Label',
      },
      {
        type: 'Input',
        key: 'data_value',
        dataType: 'String',
        title: 'Data Value:',
        value: '',
        placeholder: 'Enter Data Value',
      },
      {
        type: 'Input',
        key: 'size',
        dataType: 'String',
        title: 'Size:',
        value: '',
        placeholder: 'Enter Size',
      },
      {
        type: 'Input',
        key: 'icon_url',
        dataType: 'String',
        title: 'Icon URL:',
        value: '',
        placeholder: 'Enter Icon URL',
      },
      {
        type: 'CardPicker',
        key: 'redirect_card_id',
        dataType: 'ID',
        title: 'Card',
        value: '',
        placeholder: 'Select Card',
      },
      {
        type: 'ColorPicker',
        key: 'background_color',
        dataType: 'String',
        title: 'Background Color',
        value: '',
        placeholder: 'Select Background Color',
      },
      {
        type: 'FilePicker',
        key: 'image_data',
        dataType: 'String',
        title: 'Background Image',
        value: '',
        placeholder: 'Drag files here',
        accept: "image/*",
        mutation: {
          key: 'image_data',
          dataType: 'Upload',
        },
      },
      {
        type: 'Input',
        key: 'asset_url',
        dataType: 'String',
        title: 'Asset URL',
        value: '',
        placeholder: 'Enter asset url',
      },
      {
        type: 'ActionButtonObject',
        key: 'action',
        title: 'Action',
        dataType: 'ActionButtonObjectInput',
        subQueryKeys: [
          'action_type', 'action_resource', 'action_metadata', 'action_query'
        ],
        value: {},
        placeholder: 'Write text here!',
        preProcessor: d => {
          if (!d) return {};
          delete d.__typename;
          return d;
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
  },
  {
    name: 'Announcement',
    display: 'Announcement',
    id: 'announcement_card_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_1',
    builderIcon: svgIcons.BlackTextBoxIcon,
    customIcon: svgIcons.BlackTextBoxIcon,
    addInHomeComponent: true,
    addInNewCommunication: false,
    __typename: 'AnnouncementCardModule',
    description: 'Highlighted text used to bring attention to important messagges.',
    inputs: [
      {
        type: 'Textarea',
        key: 'text',
        dataType: 'String',
        title: 'Alert Text',
        value: '',
        placeholder: 'Enter Alert Text',
      },
      {
        type: 'ColorPicker',
        key: 'text_color',
        dataType: 'String',
        title: 'Text Color',
        value: '',
        placeholder: 'Select Text Color',
      },
      {
        type: 'ColorPicker',
        key: 'background_color',
        dataType: 'String',
        title: 'Background Color',
        value: '',
        placeholder: 'Select Background Color',
      },
      // {
      //   type: 'FilePicker',
      //   key: 'image_data',
      //   dataType: 'String',
      //   title: 'Background Image',
      //   value: '',
      //   placeholder: 'Drag files here',
      //   accept: "image/*",
      //   mutation: {
      //     key: 'image_data',
      //     dataType: 'Upload',
      //   },
      // },
      // {
      //   type: 'Input',
      //   key: 'button_title',
      //   title: 'Button Title',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write text here!',
      // },
      // {
      //   type: 'ActionButtonObject',
      //   key: 'action',
      //   title: 'Action',
      //   dataType: 'AnnounceActionButtonObjectInput',
      //   subQueryKeys: [
      //     'action_type', 'action_resource', 'action_metadata', 'action_query'
      //   ],
      //   value: null,
      //   placeholder: 'Write text here!',
      //   preProcessor: d => {
      //     if (!d) return {};
      //     delete d.__typename;
      //     return d;
      //   }
      // },
    ],
  },
  {
    name: 'Phone',
    display: 'Phone',
    id: 'button_1',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'PhoneButtonCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    // iconName: 'phone',
    builderIcon: svgIcons.BlackPhoneIcon,
    customIcon: svgIcons.PhoneLinkDeckIcon,
    description: 'A link to person or departments phone number.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'phone_number',
        dataType: 'String',
        title: 'Phone No',
        value: '',
        placeholder: 'Enter Phone No',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
  },
  {
    name: 'Sheets Card (Google & Microsoft)',
    display: 'Sheets Card (Google & Microsoft)',
    id: 'table_card',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'TableCardModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    // iconName: 'table',
    customIcon: svgIcons.TableDeckIcon,
    builderIcon: svgIcons.BlackGoogleSheetsIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Title Text',
      },
      {
        type: 'Select',
        key: 'google_sheet_integration_id',
        dataType: 'ID',
        title: 'Sheet Integration Id',
        value: null,
        placeholder: 'Select Sheet Integration',
        labelKey: 'name',
        pathToStoreValue: 'appBasicControls.organization.sheets',
        loadOptionsFromState: true,
        options: [],
      },
      {
        type: 'Checkbox',
        key: 'has_card_view',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Table has card view?',
      },
      {
        type: 'Checkbox',
        key: 'has_expanded_view',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Table has expanded view?',
      },
      {
        type: 'Checkbox',
        key: 'freeze_first_column',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Freeze first column of table?',
      },
      {
        type: 'Input',
        key: 'kpi_compare_positive_background_color',
        dataType: 'String',
        title: 'Background color when performance is good',
        value: '',
        placeholder: 'Enter color hex code',
      },
      {
        type: 'Input',
        key: 'kpi_compare_positive_negative_color',
        dataType: 'String',
        title: 'Background color when performance is not good',
        value: '',
        placeholder: 'Enter color hex code',
      },
      {
        type: 'TableColumnPicker',
        key: 'kpi',
        dataType: 'String',
        title: '',
        typeToPick: 'Kpi'
      },
      {
        type: 'TableColumnPicker',
        key: 'dimensions',
        dataType: 'String',
        title: '',
        typeToPick: 'Dimensions'
      },
      {
        type: 'TableColumnPicker',
        key: 'measures',
        dataType: 'String',
        title: '',
        typeToPick: 'Measures'
      },
      {
        type: 'TableColumnPicker',
        key: 'filters',
        dataType: 'String',
        title: '',
        typeToPick: 'Filters'
      },
      {
        type: 'TableColumnPicker',
        key: 'closing',
        dataType: 'String',
        title: '',
        typeToPick: 'Closing'
      },
    ],
  },
  {
    name: 'Single Image',
    display: 'Single Image',
    id: 'button_2',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ImageCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    parent: 'option_2',
    // iconName: 'image',
    builderIcon: svgIcons.BlackSingleImageIcon,
    customIcon: svgIcons.ImageDeckIcon,
    description: 'One image that can be clicked on to be enlarged.',
    inputs: [
      {
        type: 'FilePicker',
        key: 'file_data',
        dataType: 'String',
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
        accept: "image/*"
      },
    ],
  },
  {
    name: 'Image Gallery',
    display: 'Image Gallery',
    id: 'button_3',
    parent: 'option_2',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    __typename: 'ImageGalleryCardModule',
    description: 'Lis of image that can be clicked on to be enlarged.',
    inputs: [
      {
        type: 'FilePicker',
        key: 'gallery_images',
        subQueryKeys: ['file_data', 'caption', 'position'],
        dataType: '[GalleryMutation]',
        value: [],
        placeholder: 'Drag files here',
        alwaysShowDropZone: true,
        multiple: true,
        accept: "image/*",
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        afterProcessor: d => {
          if (!d) return [];
          return d.map(el => {
            // now if this element has type name in it that means this is not file already saved value
            // so this needs to processing
            if (el.file_data)
              return {
                file_data: el.file_data,
                // file: null,
                caption: el.caption,
                position: el.position,
              };
            if (el.file) return el;
            return {
              file_data: null,
              file: el,
              caption: '',
              position: 0,
            };
          });
        },
      },
      {
        type: 'Select',
        key: 'grid_type',
        title: 'Gallery Type',
        dataType: 'String',
        placeholder: 'Gallery Type',
        options: [
          { label: 'Grid', id: 'Grid' },
          { label: 'Slideshow', id: 'Slideshow' }
        ]
      }
    ],
    builderIcon: svgIcons.BlackImageGalleryIcon
  },
  {
    name: 'Header Image',
    display: 'Header Image',
    id: 'button_header_image',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'HeaderImageCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    parent: 'option_2',
    // iconName: 'image',
    customIcon: svgIcons.ImageDeckIcon,
    builderIcon: svgIcons.BlackHeaderImageIcon,
    description: 'A top page element with a fixed property so that it can be used as a background for the top bar elements on the page.',
    inputs: [
      {
        type: 'FilePicker',
        key: 'file_data',
        dataType: 'String',
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
        accept: "image/*"
      },
      {
        type: 'Input',
        key: 'asset_url',
        dataType: 'String',
        title: 'Asset URL',
        value: '',
        placeholder: 'Enter asset url',
      },
    ],
  },
  {
    name: 'Single Video',
    display: 'Single Video',
    id: 'button_8',
    icon: <Filter></Filter>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    __typename: 'YoutubeCardModule',
    parent: 'option_2',
    description: 'A video player powered by a streaming url that can then be played in the app.',
    inputs: [
      {
        type: 'Select',
        key: 'video_type',
        value: '',
        title: 'Video type',
        dataType: 'String',
        placeholder: 'Select video type',
        options: [
          { label: 'Youtube', id: 'Youtube' },
          { label: 'Vimeo', id: 'Vimeo' },
          { label: 'URL', id: 'URL' },
          { label: 'Library', id: 'Library' },
          { label: 'JW Player', id: 'JWPlayer' },
        ],
      },
      {
        type: 'VideoIdOrURLPicker',
        key: 'youtube_video_id',
        title: 'Video URL',
        dataType: 'String',
        value: '',
        placeholder: 'Enter Video URL',
        preProcessor: (value, input, content_module) => {
          switch (content_module.video_type) {
            case 'Vimeo':
            case 'vimeo':
              return `https://vimeo.com/${value}`;
            case 'Youtube':
            case 'youtube':
              return `https://youtu.be/${value}`;
            case 'JWPlayer':
            case 'jwplayer':
              console.log('Preprocessor______________', value);
              return value;
          };
          return value;
        }
      },
      {
        type: 'Input',
        key: 'jw_player_stream_url',
        dataType: 'String',
        title: 'JWPlayer Video URL',
        value: '',
        placeholder: 'Enter URL',
        visibilityParameter: 'video_type',
        isVisible: (value) => {
          console.log('YoutubeCardModule visibility check', value)
          return value === 'JWPlayer'
        }
      },
      {
        type: 'Input',
        key: 'jw_player_tags',
        dataType: 'String',
        title: 'JWPlayer Tags',
        value: '',
        placeholder: 'Enter Tags',
        visibilityParameter: 'video_type',
        isVisible: (value) => {
          console.log('YoutubeCardModule visibility check', value)
          return value === 'JWPlayer'
        }
      },
      {
        type: 'FilePicker',
        key: 'video_cover_photo_data',
        title: 'Video Cover Photo',
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'video_cover_photo',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      // {
      //   type: 'Checkbox',
      //   key: 'auto_play',
      //   dataType: 'Boolean',
      //   title: 'Auto Play',
      //   value: false,
      //   placeholder: 'Auto play enabled?',
      // },
      {
        type: 'Checkbox',
        key: 'auto_play_in_full_screen',
        dataType: 'Boolean',
        title: 'Auto Play In Full Screen',
        value: false,
        placeholder: 'Is Auto play enabled in full screen mode?',
      },
    ],
    builderIcon: svgIcons.BlackSingleVideoIcon
  },
  {
    name: 'Video Playlist',
    display: 'Video Playlist',
    id: 'button_31',
    parent: 'option_2',
    header: 'header_1',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    __typename: 'VideoGalleryCardModule',
    description: 'List of video player powered by a streaming url that can then be played in the app.',
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Video Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      // {
      //   type: 'FileOrLibraryPicker',
      //   key: 'cover_image_data',
      //   title: 'Image library',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: '',
      //   maxLength: 125
      // },
      {
        // type: 'JWPlayerListSelect',
        type: 'Input',
        key: 'title',
        title: 'Playlist Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        title: 'Playlist Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'VideoGallery',
        key: 'gallery_videos',
        subQueryKeys: [
          'cover_photo_data',
          'title',
          'video_url',
          'video_type',
          'loop_times',
          'does_loop',
          'position',
          'description',
          'duration',
          'attach_transition_video'
        ],
        dataType: '[GalleryVideoMutation]',
        value: [],
        placeholder: 'Drag files here',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      },
      // {
      //   type: 'Checkbox',
      //   key: 'auto_play',
      //   dataType: 'Boolean',
      //   title: 'Auto Play',
      //   value: false,
      //   placeholder: 'Auto play enabled?',
      // },
      {
        type: 'Select',
        key: 'play_mode',
        title: 'Play Mode',
        dataType: 'String',
        placeholder: 'Play Mode',
        options: [
          { id: 'full_screen', label: 'Full Screen' },
          { id: 'inline', label: 'Inline' }
        ]
      }
    ],
    builderIcon: svgIcons.BlackVideoPlaylistIcon
  },
  {
    name: 'Text',
    display: 'Text',
    id: 'button_4',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'TextCardModule',
    parent: 'option_1',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    description: 'Simple text module with basic formatting and typography options.',
    inputs: [
      {
        type: 'Textarea',
        key: 'body',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Advanced Text',
    display: 'Advanced Text',
    id: 'button_41',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'AdvanceTextCardModule',
    parent: 'option_1',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    description: 'A more complex text module that can display content in tabs or accordions.',
    inputs: [
      {
        type: 'Input',
        title: 'Title',
        key: 'title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      // {
      //   type: 'Input',
      //   title: 'Layout',
      //   key: 'layout',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write text here!',
      // },
      {
        type: 'Select',
        key: 'layout',
        title: 'Layout',
        dataType: 'String',
        placeholder: 'Layout',
        options: [
          { label: 'accordion', id: 'accordion' },
          { label: 'tab', id: 'tab' },
          { label: 'column', id: 'column' },
        ]
      },
      {
        type: 'AdvanceTextAccordion',
        title: 'Modules',
        key: 'modules',
        dataType: '[AdvanceTextModulesInput]',
        subQueryKeys: [
          'title',
          'description',
          'position',
          'is_expanded',
          'style_override'
        ],
        value: [],
        placeholder: 'Write text here!',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Banner',
    display: 'Banner',
    id: 'button_411',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'BannerCardModule',
    parent: 'option_1',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    hideFromCardBack: true,
    description: 'A marketing graphic which can be clicked on to reach the desired content or interactive location.',
    // title: String
    // sub_title: String
    // button_title: String
    // button_action_type: String
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Input',
        key: 'sub_title',
        title: 'Sub Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Input',
        key: 'button_title',
        title: 'Button Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'FilePicker',
        key: 'image_data',
        dataType: 'String',
        title: 'Banner Cover Image',
        value: '',
        placeholder: 'Write text here!',
        accept: "image/*",
        mutation: {
          key: 'image',
          dataType: 'Upload',
        },
      },
      // {
      //   type: 'Input',
      //   key: 'button_action_type',
      //   title: 'Button Action Type',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write text here!',
      // },
      {
        type: 'Select',
        key: 'button_action_type',
        title: 'Button Action Type',
        dataType: 'String',
        placeholder: 'Button Action Type',
        options: [
          { label: 'video', id: 'video' },
          { label: 'image', id: 'image' },
          { label: 'card', id: 'card' },
          { label: 'external link', id: 'link' },
          { label: 'internal action', id: 'internal_action' }
        ]
      },
      {
        type: 'ActionMetadataInput',
        key: 'button_action_metadata',
        subQueryKeys: [
          'video_url',
          'image_url',
          'card_id',
          'link',
          'internal_action'
        ],
        title: 'Button Action Resource',
        dataType: 'BannerCardButtonActionMetadataInput',
        value: {},
        placeholder: 'Write text here!',
        preProcessor: d => {
          if (!d) return {};
          delete d.__typename;
          return d;
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Action Button',
    display: 'Action Button',
    id: 'button_4111',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ActionButtonCardModule',
    parent: 'option_1',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    hideFromCardBack: true,
    description: 'Simple text module with a header image that also includes a buton for in app interactivity.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Input',
        key: 'sub_title',
        title: 'Sub Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Input',
        key: 'button_title',
        title: 'Button Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Textarea',
        key: 'description',
        title: 'Description',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'FilePicker',
        key: 'image_data',
        title: 'Cover Image',
        dataType: 'String',
        value: '',
        mutation: {
          key: 'image',
          dataType: 'Upload',
        },
        placeholder: 'Write text here!',
      },
      {
        type: 'ActionButtonObject',
        key: 'action',
        title: 'Action',
        dataType: 'ActionButtonObjectInput',
        subQueryKeys: [
          'action_type', 'action_resource', 'action_metadata', 'action_query'
        ],
        value: {
          action_type: '',
          action_resource: '',
          action_metadata: '',
          action_query: ''
        },
        placeholder: 'Write text here!',
        preProcessor: d => {
          if (!d) return {
            action_type: '',
            action_resource: '',
            action_metadata: '',
            action_query: ''
          };
          delete d.__typename;
          return d;
        },
        afterProcessor: d => {
          if (!d) return {
            action_type: '',
            action_resource: '',
            action_metadata: '',
            action_query: ''
          };
          return d;
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      },
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Single Audio',
    display: 'Single Audio',
    id: 'button_5',
    icon: <Filter></Filter>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    __typename: 'SingleAudioCardModule',
    parent: 'option_2',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        value: '',
        title: 'Title',
        dataType: 'String',
        placeholder: 'Enter audio title',
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        value: '',
        title: 'Description',
        dataType: 'String',
        placeholder: 'Enter audio description',
      },
      {
        type: 'FilePicker',
        key: 'cover_photo_data',
        title: 'Cover Photo',
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'FilePicker',
        key: 'file_data',
        title: 'Audio File',
        dataType: 'String',
        multiple: false,
        accept: "audio/*",
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
    ],
    builderIcon: svgIcons.BlackSingleAudioIcon
  },
  {
    name: 'Audio Playlist',
    display: 'Audio Playlist',
    id: 'button_7',
    __typename: 'AudioCardModule',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_2',
    header: 'header_1',
    builderIcon: svgIcons.BlackAudioPlaylistIcon,
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Playlist Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'Input',
        key: 'title',
        title: 'Playlist Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        title: 'Playlist Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'AudioPicker',
        key: 'audios',
        value: [],
        subQueryKeys: [
          'file_data',
          'caption',
          'position',
          'description',
          'duration',
          'cover_photo_data'
        ],
        dataType: '[AudioMutation]',
        placeholder: 'Drag files here',
        multiple: true,
        accept: "audio/*",
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        // afterProcessor: d => {
        //   if (!d) return [];
        //   return d.map(el => {
        //     // now if this element has type name in it that means this is not file already saved value
        //     // so this needs to processing
        //     if (el.file_data)
        //       return {
        //         file_data: el.file_data,
        //         // file: null,
        //         caption: el.caption,
        //         position: el.position,
        //       };
        //     if (el.file) return el;
        //     return {
        //       file_data: null,
        //       file: el,
        //       caption: '',
        //       position: 0,
        //     };
        //   });
        // },
      },
    ],
  },
  {
    name: 'Attachment',
    display: 'Attachment',
    id: 'button_6',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'AttachmentCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    parent: 'option_2',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'Checkbox',
        key: 'display_download_link',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Make download link available',
      },
      {
        type: 'FilePicker',
        key: 'attachments',
        value: [],
        subQueryKeys: ['file_data', 'text'],
        dataType: '[AttachmentMutation]',
        placeholder: 'Drag files here',
        multiple: true,
        accept: '.pdf,.doc',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        afterProcessor: d => {
          if (!d) return [];
          return d.map(el => {
            // now if this element has type name in it that means this is not file already saved value
            // so this needs to processing
            if (el.file_data)
              return {
                file_data: el.file_data,
                // file: null,
                text: el.text,
              };
            if (el.file) return el;
            return {
              file_data: null,
              file: el,
              text: '',
            };
          });
        },
      },
    ],
  },
  {
    name: 'CSV Card',
    display: 'CSV Card',
    id: 'button_6',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'CsvCardModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    description: 'Table data uploaded that is displayed in a simple table.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Table Name',
        value: '',
        placeholder: 'Enter Table Name',
      },
      {
        type: 'FilePicker',
        key: 'data',
        value: [],
        multiple: true,
        accept: '.csv',
        dataType: 'Upload',
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
      },
      {
        type: 'Input',
        key: 'headers',
        dataType: 'String',
        title: 'Override Headers',
        value: '',
        placeholder: 'Enter Headers to override default',
      },
      {
        type: 'Checkbox',
        key: 'is_mongo_connected',
        dataType: 'Boolean',
        title: 'Connect to mongodb',
        value: false,
        placeholder: 'Is mongodb connected?',
      },
      {
        type: 'MongoIntegrationPicker',
        key: 'organization_mongo_id',
        dataType: 'ID',
        title: 'MongoDB Integration',
        value: null,
        placeholder: 'Select mongodb integration',
        visibilityParameter: 'is_mongo_connected',
        isVisible: (value) => {
          return value === true
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'pipeline',
        dataType: 'String',
        title: 'Mongo Pipeline',
        value: '',
        placeholder: 'Enter Mongo Pipeline',
        visibilityParameter: 'is_mongo_connected',
        element_type: 'json',
        isVisible: (value) => {
          return value === true
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ],
  },
  {
    name: 'Map Card',
    display: 'Map Card (Advanced)',
    id: 'button_6_123',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'MapCardModule',
    addInNewCommunication: false,
    addInHomeComponent: false,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Title',
      },
      {
        type: 'Input',
        key: 'address',
        dataType: 'String',
        title: 'Address',
        value: '',
        placeholder: 'Enter Address',
      },
      {
        type: 'Input',
        key: 'city',
        dataType: 'String',
        title: 'City',
        value: '',
        placeholder: 'Enter City',
      },
      {
        type: 'Input',
        key: 'state',
        dataType: 'String',
        title: 'State',
        value: '',
        placeholder: 'Enter State',
      },
      {
        type: 'Input',
        key: 'zip',
        dataType: 'String',
        title: 'Zip',
        value: '',
        placeholder: 'Enter Zip',
      },
      {
        type: 'Input',
        key: 'latitude',
        dataType: 'String',
        title: 'Latitude',
        value: '',
        placeholder: 'Enter Latitude',
      },
      {
        type: 'Input',
        key: 'longitude',
        dataType: 'String',
        title: 'Longitude',
        value: '',
        placeholder: 'Enter Longitude',
      },
      {
        type: 'Textarea',
        key: 'notes',
        dataType: 'String',
        title: 'Notes',
        value: '',
        placeholder: 'Enter Notes',
      },
    ],
  },
  {
    name: 'Chart Card',
    display: 'Chart Card (Advanced)',
    id: 'button_611',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ChartCardModule',
    addInNewCommunication: false,
    addInQuiz: true,
    addInHomeComponent: true,
    hideFromCardBack: true,
    parent: 'option_4',
    customIcon: svgIcons.DashboardDeckIcon,
    builderIcon: svgIcons.DashboardDeckIcon,
    // builderIcon: svgIcons.BlackAttachmentIcon,
    description: 'Data visualization powered by external data integrations.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Chart Title',
        value: '',
        placeholder: 'Enter Chart Title',
      },
      {
        type: 'Textarea',
        key: 'description',
        dataType: 'String',
        title: 'Chart Description',
        value: '',
        placeholder: 'Enter Chart Description',
      },
      {
        type: 'Input',
        key: 'help_text',
        dataType: 'String',
        title: 'Chart Help text',
        value: '',
        placeholder: 'Enter Chart Help Text',
      },
      {
        type: 'Select',
        key: 'chart_type',
        title: 'Chart Type',
        dataType: 'String',
        placeholder: 'Chart Type',
        span: 6,
        options: [
          {
            "label": "Bar Chart",
            "id": "bar"
          },
          {
            "label": "Horizontal Bar Chart",
            "id": "horizontal-bar"
          },
          {
            "label": "Line Chart",
            "id": "line"
          },
          {
            "label": "Pie Chart",
            "id": "pie"
          },
          {
            "label": "Table Chart",
            "id": "table"
          },
          {
            "label": "Area Chart",
            "id": "area"
          },
          {
            "label": "Scatter Chart",
            "id": "scatter"
          },
          {
            "label": "Data Chip",
            "id": "datachip"
          },
          {
            "label": "Date Selector",
            "id": "date_picker"
          },
          {
            "label": "Form Response",
            "id": "form_response"
          }
        ]
      },
      {
        type: 'Input',
        key: 'background_color',
        dataType: 'String',
        title: 'Background Color',
        value: '',
        placeholder: 'Enter Background Color',
        span: 6,
      },
      {
        type: 'ApiPathPicker',
        key: 'api_path_id',
        dataType: 'ID',
        title: 'Api Path',
        value: null,
        placeholder: 'Select api path',
      },
      {
        type: 'BaseUiTextArea',
        key: 'json',
        dataType: 'String',
        title: 'Chart Data',
        value: '',
        placeholder: 'Enter Chart Data',
      },
      {
        type: 'BaseUiTextArea',
        key: 'pipeline',
        dataType: 'String',
        title: 'Mongo Pipeline',
        value: '',
        placeholder: 'Enter Mongo Pipeline',
      },
      {
        type: 'BaseUiTextArea',
        key: 'chart_props',
        dataType: 'String',
        title: 'Chart Props',
        value: '',
        placeholder: 'Enter Chart Props',
      },
      {
        type: 'Checkbox',
        key: 'is_data_link_enabled',
        dataType: 'Boolean',
        title: 'Data Link Enabled',
        value: false,
        placeholder: 'Is data link enabled?',
      },
      {
        type: 'ChannelPicker',
        key: 'data_link_channel_ids',
        dataType: '[ID!]',
        title: 'Data Link Channel',
        value: '',
        placeholder: 'Select Channel',
        visibilityParameter: 'is_data_link_enabled',
        isVisible: (value) => {
          return value
        }
      },
      {
        type: 'BaseUiTextArea',
        key: 'data_link_query',
        dataType: 'String',
        title: 'Data Link Query',
        value: '',
        placeholder: 'Filter Query',
        visibilityParameter: 'is_data_link_enabled',
        isVisible: (value) => {
          return value
        }
      },
    ],
  },
  {
    name: 'Embed Code',
    display: 'Embed Code',
    id: 'button_9',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'EmbedCodeCardModule',
    parent: 'option_4',
    builderIcon: svgIcons.BlackEmbedIcon,
    hideFromCardBack: false,
    inputs: [
      {
        type: 'BaseUiTextArea',
        key: 'code',
        dataType: 'String',
        title: 'Embed code snippet',
        value: '',
        placeholder: 'Enter Embed code snippet',
      },
      {
        type: 'Input',
        key: 'description',
        dataType: 'String',
        title: 'Embed code description',
        value: '',
        placeholder: 'Enter Embed code description',
      },
    ],
  },
  {
    name: 'Business Card',
    display: 'Business Card',
    id: 'button_10',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ContactCardModule',
    parent: 'option_4',
    inputs: [
      {
        type: 'Input',
        key: 'personal_first_name',
        dataType: 'String',
        title: 'First Name',
        value: '',
        placeholder: 'Enter First Name'
      },
      {
        type: 'Input',
        key: 'personal_last_name',
        dataType: 'String',
        title: 'Last Name',
        value: '',
        placeholder: 'Enter Last Name'
      },
      {
        type: 'Input',
        key: 'personal_position',
        dataType: 'String',
        title: 'Title/Position',
        value: '',
        placeholder: 'Enter Title/Position'
      },
      {
        type: 'Input',
        key: 'personal_department',
        dataType: 'String',
        title: 'Department',
        value: '',
        placeholder: 'Department'
      },
      {
        type: 'ContactMethod',
        key: 'contact_info_cards',
        dataType: '[ContactInfoCardInput]',
        subQueryKeys: [
          'contact_type',
          'phone_title',
          'phone_type',
          'phone_number',
          'phone_ext',
          'email_address',
          'website_title',
          'website_url',
          'address_title',
          'address_address',
          'address_unit',
          'address_city',
          'address_state',
          'address_zip',
          'position',
          'contact_notes'
        ],
        title: '',
        value: [],
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      }
    ],
    builderIcon: svgIcons.BlackBusinessCardIcon,
  },
  // {
  //   name: 'Meeting',
  //   display: 'Zoom Meeting',
  //   id: 'button_11',
  //   __typename: 'MeetingCardModule',
  //   icon: <Plus></Plus>,
  //   parent: 'option_4',
  //   inputs: [
  //     {
  //       type: 'Select',
  //       key: 'zoom_id',
  //       dataType: 'ID',
  //       title: 'Zoom Account',
  //       value: null,
  //       placeholder: 'Select Zoom Account',
  //       labelKey: 'nickname',
  //       loadOptionsFromState: true,
  //       pathToStoreValue: 'appBasicControls.organization.zooms',
  //       options: [],
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_name',
  //       dataType: 'String',
  //       title: 'Meeting Name',
  //       value: '',
  //       placeholder: 'Enter Meeting Name',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_id',
  //       dataType: 'ID',
  //       title: 'Meeting ID',
  //       value: '',
  //       placeholder: 'Enter Meeting ID',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_passcode',
  //       dataType: 'String',
  //       title: 'Meeting Passcode',
  //       value: '',
  //       placeholder: 'Enter Meeting Passcode',
  //     },
  //     {
  //       type: 'Date',
  //       key: 'meeting_date',
  //       dataType: 'String',
  //       title: 'Meeting Date',
  //       value: '',
  //       placeholder: 'Enter Meeting Date',
  //     },
  //     {
  //       type: 'Time',
  //       key: 'meeting_start_time',
  //       dataType: 'String',
  //       title: 'Meeting Start Time',
  //       value: '',
  //       placeholder: 'Enter Meeting Start Time',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_duration',
  //       dataType: 'String',
  //       title: 'Meeting Duration',
  //       value: '',
  //       placeholder: 'Enter Meeting Duration',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_notes',
  //       dataType: 'String',
  //       title: 'Meeting Notes',
  //       value: '',
  //       placeholder: 'Enter Meeting Notes',
  //     },
  //   ],
  // },
  // {
  //   name: 'Live Stream',
  //   display: 'Live Stream',
  //   id: 'liveStream',
  //   icon: <ArrowDown></ArrowDown>,
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       title: 'Live Stream Title',
  //       value: '',
  //       placeholder: 'Enter Live Stream title'
  //     },
  //     {
  //       type: 'Input',
  //       key: 'url',
  //       title: 'Live Stream URL',
  //       value: '',
  //       placeholder: 'Enter Live Stream url'
  //     }
  //   ]
  // },
  {
    name: 'Full Screen Web Card',
    display: 'Full Screen Web Card',
    id: 'button_10',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'WebCardModule',
    parent: 'option_4',
    addInHomeComponent: true,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
      },
      {
        type: 'Input',
        key: 'link',
        title: 'Link',
        dataType: 'String',
        value: '',
        placeholder: 'Write link here!',
      },
      {
        type: 'Select',
        key: 'type',
        dataType: 'String',
        title: 'Type',
        value: 'internal',
        placeholder: 'Select Type',
        labelKey: 'label',
        options: [{
          "label": "Internal",
          "value" : "internal",
          "id": "internal"
        },{
          "label": "External",
          "value" : "external",
          "id": "external"
        }],
      },
        {
        type: 'Select',
        key: 'display_type',
        dataType: 'String',
        title: 'Display Type',
        value: 'full_screen',
        placeholder: 'Select Display Type',
        labelKey: 'label',
        options: [{
          "label": "Button",
          "value" : "button",
          "id" : "button",
        },{
          "label": "Full Screen",
          "value" : "full_screen",
          "id" : "full_screen"
        }],
      },
    ]
  },
  // {
  //   name: 'Salesforce Dashboard',
  //   display: 'Salesforce Dashboard',
  //   id: 'button_salesforce',
  //   icon: <Grab></Grab>,
  //   bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
  //   __typename: 'SalesforceAggregatePointsModule',
  //   parent: 'option_5',
  //   addInNewCommunication: false,
  //   addInHomeComponent: false,
  //   // point_source_field: ID
  //   // point_source_title: String
  //   // card_link_id: ID
  //   // card_link_title: String,
  //   // iconName: 'chart-line',
  //   customIcon: svgIcons.DashboardDeckIcon,
  //   builderIcon: svgIcons.BlackDashboardIcon,
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'point_source_title',
  //       title: 'Point source title',
  //       dataType: 'String',
  //       value: '',
  //       placeholder: 'point source title to display on app',
  //     },
  //     {
  //       type: 'CustomFieldPicker',
  //       key: 'point_source_field',
  //       dataType: 'ID',
  //       title: 'Custom field',
  //       value: null,
  //       placeholder: 'Select custom field',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'card_link_title',
  //       title: 'Card link title',
  //       dataType: 'String',
  //       value: '',
  //       placeholder: 'Write your card link title here',
  //     },
  //     {
  //       type: 'CardPicker',
  //       key: 'card_link_id',
  //       dataType: 'ID',
  //       title: 'Card',
  //       value: null,
  //       placeholder: 'Select Card',
  //     },
  //   ],
  // },
  // {
  //   name: 'Single Use Button',
  //   display: 'Single Use Button',
  //   id: 'button_SingleUseButtonCardModule',
  //   icon: <Plus></Plus>,
  //   bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
  //   parent: 'option_5',
  //   __typename: 'SingleUseButtonCardModule',
  //   addInNewCommunication: true,
  //   builderIcon: svgIcons.BlackSingleUseButtonIcon,
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       dataType: 'String',
  //       title: 'Button Text',
  //       value: '',
  //       placeholder: 'Enter Button Text',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'url',
  //       dataType: 'String',
  //       title: 'Button URL',
  //       value: '',
  //       placeholder: 'Enter Button URL',
  //     },
  //     {
  //       type: 'Checkbox',
  //       key: 'is_selected',
  //       dataType: 'Boolean',
  //       title: 'Selection',
  //       value: false,
  //       placeholder: 'Is button selected?',
  //     },
  //   ],
  // },
  {
    name: 'Channel Card',
    display: 'Channel Link',
    id: 'channel_card_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'ChannelCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    // iconName: 'sim-card',
    builderIcon: svgIcons.BlackCardIcon,
    customIcon: svgIcons.CardLinkDeckIcon,
    description: 'A link to another channel in the application.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'ChannelPicker',
        key: 'redirect_channel_id',
        dataType: 'ID',
        title: 'Channel',
        value: '',
        placeholder: 'Select Channel',
      },
    ],
  },
  {
    id: 'cardware_card_module',
    display: 'Cardware Form Module (Advanced)',
    name: 'Cardware Form Module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'CardwareFormModule',
    addInHomeComponent: true,
    // hideFromCardBack: true,
    description: 'Professional form builder for data stored within your application data storage.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Form Title',
        value: '',
        placeholder: 'Enter Form Title',
      },
      {
        type: 'Input',
        key: 'description',
        dataType: 'String',
        title: 'Form Description',
        value: '',
        placeholder: 'Enter Form Description',
      },
      {
        type: 'Checkbox',
        key: 'connected_to_crm',
        dataType: 'Boolean',
        title: 'Connected to CRM',
        value: false,
        placeholder: 'Is connected to crm?',
      },
      {
        type: 'FormInputList',
        key: 'schema',
        mutation: {
          key: 'schemaArr',
          dataType: '[schemaInput]',
        },
        dataType: 'String',
        value: [],
        placeholder: '',
        HideInAdd: true,
        preProcessor: d => {
          if (!d) return [];
          console.log('preProcessor d', d)
          let dArr = JSON.parse(d)
          return dArr
            ?.map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      },
      {
        type: 'Select',
        key: 'form_type',
        title: 'Form Presentation Type',
        dataType: 'String',
        placeholder: 'Type',
        options: [
          { id: 'single_form', label: 'Single page' },
          { id: 'stepped_form', label: 'Stepped' },
        ]
      },
      {
        type: 'Input',
        key: 'collection_id',
        dataType: 'String',
        title: 'Collection ID',
        value: '',
        placeholder: 'Enter Collection ID',
      },
      {
        type: 'Input',
        key: 'table_id',
        dataType: 'String',
        title: 'Table ID',
        value: '',
        placeholder: 'Enter Table ID',
      },
      {
        type: 'CrunchbaseIntegrationPicker',
        key: 'crunchbase_integration_id',
        dataType: 'String',
        title: 'Crunchbase integration',
        value: null,
        HideInAdd: true,
        placeholder: 'Select integration',
      },
    ]
  },
  {
    id: 'share_point_form_module',
    display: 'Share Point Form Module (Advanced)',
    name: 'Share Point Form Module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'SharePointFormModule',
    addInHomeComponent: true,
    hideFromCardBack: true,
    description: 'Professional form builder for data stored within your organizations Microsoft Sharepoint.',
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Form Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here to upload...',
      },
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Form Title',
        value: '',
        placeholder: 'Enter Form Title',
      },
      {
        type: 'Input',
        key: 'description',
        dataType: 'String',
        title: 'Form Description',
        value: '',
        placeholder: 'Enter Form Description',
      },
      {
        type: 'Select',
        key: 'sharepoint_spreadsheet_id',
        dataType: 'ID',
        labelKey: 'name',
        value: null,
        title: 'Sharepoint File',
        value: '',
        placeholder: 'Select SharePoint SpreadSheet',
        pathToStoreValue: 'appBasicControls.organization.microsoftSharePointSheets',
        loadOptionsFromState: true,
        options: [],
        optionFilter: (options) => {
          // return options.filter(el => (el.name && el.name.includes('(Microsoft)')) || (el.name && el.name.includes('(Share Point)')));
          return options.filter(el => (el.__typename && el.__typename.includes('OrganizationSharePointSheets')));
        }
      },
      {
        type: 'FormInputList',
        key: 'schema',
        mutation: {
          key: 'schemaArr',
          dataType: '[schemaInput]',
        },
        dataType: 'String',
        value: [],
        placeholder: '',
        HideInAdd: true,
        preProcessor: d => {
          if (!d) return [];
          return JSON.parse(d);
        },
        // afterProcessing: (d) => {
        //   return JSON.parse(d);
        // }
      },
      // {
      //   type: 'Checkbox',
      //   key: 'is_single_form',
      //   dataType: 'Boolean',
      //   title: 'Single Form',
      //   value: false,
      //   placeholder: 'Is Single Form?',
      // },
      {
        type: 'Select',
        key: 'form_type',
        title: 'Type',
        dataType: 'String',
        placeholder: 'Type',
        options: [
          { id: 'single_form', label: 'Single Form' },
          { id: 'stepped_form', label: 'Stepped Form' },
          { id: 'table_form', label: 'Table Form' }
        ]
      },
      {
        type: 'Checkbox',
        key: 'show_on_home_screen',
        dataType: 'Boolean',
        title: 'Show on Home Screen?',
        value: false,
        placeholder: '',
      },
      {
        type: 'Input',
        key: 'dialog_open_button_text',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Open Form',
        visibilityParameter: 'show_on_home_screen',
        isVisible: (value) => {
          return !value
        }
      },
      {
        type: 'Checkbox',
        key: 'can_multiple_response',
        dataType: 'Boolean',
        title: 'Can add multiple response?',
        value: false,
        placeholder: '',
      },
      {
        type: 'Checkbox',
        key: 'is_reference_form',
        dataType: 'Boolean',
        title: 'Is Reference Forms?',
        value: false,
        placeholder: '',
        HideInAdd: true
      },
      // {
      //   type: 'Checkbox',
      //   key: 'is_template',
      //   dataType: 'Boolean',
      //   title: 'Is Template Forms?',
      //   value: false,
      //   placeholder: '',
      //   HideInAdd: true
      // },
      {
        type: 'Select',
        key: 'tags',
        dataType: '[Tag]',
        subQueryKeys: ['id', 'name'],
        title: 'tags',
        mutation: {
          key: 'tag_ids',
          dataType: '[ID]',
        },
        value: [],
        placeholder: '',
        HideInAdd: true,
        multi: true,
        loadOptionsFromComponentState: true,
        pathToStoreValue: 'tags',
        HideInAdd: true,
        isVisible: () => false
      }
      // {
      //   type: 'Select',
      //   key: 'forms_list_reference',
      //   dataType: '[SharePointFormModuleTemplate]',
      //   subQueryKeys: ['id', 'title'],
      //   title: 'Forms List For References',
      //   mutation: {
      //     key: 'reference_form_ids',
      //     dataType: '[ID]',
      //   },
      //   placeholder: 'Select Forms',
      //   multi: true,
      //   loadOptionsFromComponentState: true,
      //   options: [],
      //   pathToStoreValue: 'homeComponentFormList',
      //   visibilityParameter: 'is_reference_form',
      //   HideInAdd: true,
      //   isVisible: (value, show) => {
      //     console.log('isVisible', value)
      //     return value || false
      //   }
      // },
      // {
      //   type: 'Select',
      //   key: 'reference_form_field_name',
      //   title: 'Reference form field name',
      //   dataType: 'String',
      //   loadOptionsFromComponentState: true,
      //   options: [],
      //   formatOptions: (options = []) => {
      //     if(!options) {
      //       return []
      //     }
      //     return options.map(el => {
      //       return ({
      //         id: el.name,
      //         label: el.displayName
      //       })
      //     })
      //   },
      //   pathToStoreValue: 'formElements',
      //   HideInAdd: true,
      //   visibilityParameter: 'is_reference_form',
      //   isVisible: (value) => {
      //     return value || false
      //   }
      // },
    ]
  },
  // {
  //   id: 'database_form_module',
  //   display: 'Database Form Module',
  //   name: 'Database Form Module',
  //   icon: <Plus></Plus>,
  //   bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
  //   parent: 'option_4',
  //   __typename: 'DatabaseFormModule',
  //   addInHomeComponent: true,
  //   inputs: [
  //     {
  //       type: 'FilePicker',
  //       key: 'cover_image_data',
  //       title: 'Form Cover Image',
  //       showHint: false,
  //       dataType: 'String',
  //       multiple: false,
  //       accept: "image/*",
  //       mutation: {
  //         key: 'cover_image',
  //         dataType: 'Upload',
  //       },
  //       value: '',
  //       placeholder: 'Drag files here to upload...',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       dataType: 'String',
  //       title: 'Form Title',
  //       value: '',
  //       placeholder: 'Enter Form Title',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'description',
  //       dataType: 'String',
  //       title: 'Form Description',
  //       value: '',
  //       placeholder: 'Enter Form Description',
  //     },
  //     {
  //       type: 'Select',
  //       key: 'mongo_id',
  //       dataType: 'ID',
  //       labelKey: 'name',
  //       value: null,
  //       title: 'Mongo Integration',
  //       value: '',
  //       placeholder: 'Select Mongo Integration',
  //       pathToStoreValue: 'appBasicControls.organization.mongo',
  //       loadOptionsFromState: true,
  //       options: [],
  //       optionFilter: (options) => {
  //         // return options.filter(el => (el.name && el.name.includes('(Microsoft)')) || (el.name && el.name.includes('(Share Point)')));
  //         return options.filter(el => (el.__typename && el.__typename.includes('OrganizationMongo')));
  //       }
  //     },
  //     {
  //       type: 'FormInputListDB',
  //       key: 'schema',
  //       mutation: {
  //         key: 'schemaArr',
  //         dataType: '[schemaInput]',
  //       },
  //       dataType: 'String',
  //       value: [],
  //       placeholder: '',
  //       HideInAdd: true,
  //       preProcessor: d => {
  //         if (!d) return [];
  //         return JSON.parse(d);
  //       },
  //       // afterProcessing: (d) => {
  //       //   return JSON.parse(d);
  //       // }
  //     },
  //     // {
  //     //   type: 'Checkbox',
  //     //   key: 'is_single_form',
  //     //   dataType: 'Boolean',
  //     //   title: 'Single Form',
  //     //   value: false,
  //     //   placeholder: 'Is Single Form?',
  //     // },
  //     {
  //       type: 'Select',
  //       key: 'form_type',
  //       title: 'Type',
  //       dataType: 'String',
  //       placeholder: 'Type',
  //       options: [
  //         { id: 'single_form', label: 'Single Form' },
  //         { id: 'stepped_form', label: 'Stepped Form' },
  //         { id: 'table_form', label: 'Table Form' }
  //       ]
  //     },
  //     {
  //       type: 'Checkbox',
  //       key: 'show_on_home_screen',
  //       dataType: 'Boolean',
  //       title: 'Show on Home Screen?',
  //       value: false,
  //       placeholder: '',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'dialog_open_button_text',
  //       dataType: 'String',
  //       title: 'Button Text',
  //       value: '',
  //       placeholder: 'Open Form',
  //       visibilityParameter: 'show_on_home_screen',
  //       isVisible: (value) => {
  //         return !value
  //       }
  //     },
  //     {
  //       type: 'Checkbox',
  //       key: 'can_multiple_response',
  //       dataType: 'Boolean',
  //       title: 'Can add multiple response?',
  //       value: false,
  //       placeholder: '',
  //     },
  //     // {
  //     //   type: 'Checkbox',
  //     //   key: 'is_reference_form',
  //     //   dataType: 'Boolean',
  //     //   title: 'Is Reference Forms?',
  //     //   value: false,
  //     //   placeholder: '',
  //     //   HideInAdd: true
  //     // },
  //     // {
  //     //   type: 'Checkbox',
  //     //   key: 'is_template',
  //     //   dataType: 'Boolean',
  //     //   title: 'Is Template Forms?',
  //     //   value: false,
  //     //   placeholder: '',
  //     //   HideInAdd: true
  //     // },
  //     // {
  //     //   type: 'Select',
  //     //   key: 'tags',
  //     //   dataType: '[Tag]',
  //     //   subQueryKeys: ['id', 'name'],
  //     //   title: 'tags',
  //     //     mutation: {
  //     //     key: 'tag_ids',
  //     //     dataType: '[ID]',
  //     //   },
  //     //   value: [],
  //     //   placeholder: '',
  //     //   HideInAdd: true,
  //     //   multi: true,
  //     //   loadOptionsFromComponentState: true,
  //     //   pathToStoreValue: 'tags',
  //     //   HideInAdd: true,
  //     //   isVisible: () => false
  //     // }
  //     // {
  //     //   type: 'Select',
  //     //   key: 'forms_list_reference',
  //     //   dataType: '[SharePointFormModuleTemplate]',
  //     //   subQueryKeys: ['id', 'title'],
  //     //   title: 'Forms List For References',
  //     //   mutation: {
  //     //     key: 'reference_form_ids',
  //     //     dataType: '[ID]',
  //     //   },
  //     //   placeholder: 'Select Forms',
  //     //   multi: true,
  //     //   loadOptionsFromComponentState: true,
  //     //   options: [],
  //     //   pathToStoreValue: 'homeComponentFormList',
  //     //   visibilityParameter: 'is_reference_form',
  //     //   HideInAdd: true,
  //     //   isVisible: (value, show) => {
  //     //     console.log('isVisible', value)
  //     //     return value || false
  //     //   }
  //     // },
  //     // {
  //     //   type: 'Select',
  //     //   key: 'reference_form_field_name',
  //     //   title: 'Reference form field name',
  //     //   dataType: 'String',
  //     //   loadOptionsFromComponentState: true,
  //     //   options: [],
  //     //   formatOptions: (options = []) => {
  //     //     if(!options) {
  //     //       return []
  //     //     }
  //     //     return options.map(el => {
  //     //       return ({
  //     //         id: el.name,
  //     //         label: el.displayName
  //     //       })
  //     //     })
  //     //   },
  //     //   pathToStoreValue: 'formElements',
  //     //   HideInAdd: true,
  //     //   visibilityParameter: 'is_reference_form',
  //     //   isVisible: (value) => {
  //     //     return value || false
  //     //   }
  //     // },
  //   ]
  // },
  {
    id: 'datalist_form_module',
    display: 'Datalist',
    name: 'Datalist (Advanced)',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'DataListCardModule',
    addInHomeComponent: true,
    hideFromCardBack: true,
    description: 'List of locations saved in the database',
    inputs: [
      {
        type: 'Select',
        key: 'data_list_type',
        title: 'List Type',
        dataType: 'String',
        placeholder: 'Type',
        options: [
          { id: 'location', label: 'location' }
        ]
      },
      {
        type: 'Select',
        key: 'data_list_source',
        title: 'Data Source',
        dataType: 'String',
        placeholder: 'Type',
        options: [
          { id: 'locations', label: 'locations' }
        ]
      },
    ]
  },
  {
    name: 'Card Title',
    display: 'Card Title',
    id: 'card_title_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardTitleCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Subtitle',
    display: 'Card Subtitle',
    id: 'card_subtitle_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardSubTitleCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Tags',
    display: 'Card Tags',
    id: 'card_tags_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardTagsCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Filters',
    display: 'Card Filters',
    id: 'card_filters_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardFiltersCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Event Card',
    display: 'Event Card',
    id: 'card_events_button',
    icon: <Grab></Grab>,
    __typename: 'EventCardBackModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    customIcon: svgIcons.DashboardDeckIcon,
    builderIcon: svgIcons.BlackDashboardIcon,
    description: 'Informational card pertaining to an event.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write your title here',
      },
      {
        type: 'Textarea',
        key: 'description',
        title: 'Description',
        dataType: 'String',
        value: '',
        placeholder: 'Write your description here',
      },
      {
        type: 'DateTimeRange',
        key: 'event_date',
        title: 'Event Date',
        dataType: 'String',
        value: '',
        placeholder: 'Date',
        extraStateKeyToUpdate: ['start_time', 'end_time'],
      },
      {
        type: 'Time',
        key: 'start_time',
        dataType: 'String',
        title: 'Start Time',
        value: '',
        placeholder: 'Start Time',
        isVisible: () => false,
      },
      {
        type: 'Time',
        key: 'end_time',
        dataType: 'String',
        title: 'End Time',
        value: '',
        placeholder: 'End Time',
        isVisible: () => false,
      },
      {
        type: 'LocationModulePicker',
        key: 'location_tags',
        title: 'Locations',
        dataType: '[ID]',
        value: '',
        placeholder: 'Select Locations',
      },
      {
        type: 'Input',
        key: 'link',
        title: 'Button Link',
        dataType: 'String',
        value: '',
        placeholder: 'Write button link here!',
      },
      // {
      //   type: 'CardPicker',
      //   key: 'map_id',
      //   dataType: 'ID',
      //   title: 'Association to Map Card',
      //   value: '',
      //   placeholder: 'Select Card',
      // },
      // {
      //   type: 'Input',
      //   key: 'event_tags',
      //   dataType: 'String',
      //   title: 'Tags',
      //   value: '',
      //   placeholder: 'Enter tags',
      // },
      {
        type: 'Textarea',
        key: 'parking_notes',
        dataType: 'String',
        title: 'Parking Notes',
        value: '',
        placeholder: 'Enter parking notes',
      },
      {
        type: 'Textarea',
        key: 'map_notes',
        dataType: 'String',
        title: 'Map Notes',
        value: '',
        placeholder: 'Enter map notes',
      },
      {
        type: 'FilePicker',
        key: 'file',
        dataType: 'String',
        title: 'Featured Image',
        mutation: {
          key: 'file_upload',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
        accept: "image/*"
      }
    ],
  },
  // {
  //   name: 'Count Down Card',
  //   display: 'Count Down Card',
  //   id: 'card_events_button',
  //   icon: <Grab></Grab>,
  //   __typename: 'CountDownCardModule',
  //   addInNewCommunication: false,
  //   addInHomeComponent: true,
  //   customIcon: svgIcons.DashboardDeckIcon,
  //   builderIcon: svgIcons.BlackDashboardIcon,
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       title: 'Title',
  //       dataType: 'String',
  //       value: '',
  //       placeholder: 'Write your title here',
  //     },
  //     // {
  //     //   type: 'Input',
  //     //   key: 'days',
  //     //   title: 'Days',
  //     //   dataType: 'String',
  //     //   value: '',
  //     //   placeholder: 'Days',
  //     // },
  //     // {
  //     //   type: 'Input',
  //     //   key: 'hours',
  //     //   dataType: 'String',
  //     //   title: 'Hours',
  //     //   value: '',
  //     //   placeholder: 'Hours',
  //     // },
  //     // {
  //     //   type: 'Input',
  //     //   key: 'minutes',
  //     //   dataType: 'String',
  //     //   title: 'Minutes',
  //     //   value: '',
  //     //   placeholder: 'Minutes',
  //     // },
  //     {
  //       type: 'Date',
  //       key: 'date',
  //       title: 'Date',
  //       dataType: 'Date',
  //       value: '',
  //       placeholder: 'Days',
  //     },
  //   ]
  // },
  {
    name: 'Location',
    display: 'Location',
    id: 'location',
    icon: <Grab></Grab>,
    __typename: 'LocationModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    customIcon: svgIcons.DashboardDeckIcon,
    builderIcon: svgIcons.BlackDashboardIcon,
    description: 'List of locations stored in the database',
    inputs: [
      {
        type: 'Input',
        key: 'name',
        title: 'Name',
        dataType: 'String',
        value: '',
        placeholder: 'Name',
      },
      {
        type: 'Input',
        key: 'address',
        title: 'Address',
        dataType: 'String',
        value: '',
        placeholder: 'Address',
      },
      {
        type: 'Input',
        key: 'address2',
        title: 'Address 2',
        dataType: 'String',
        value: '',
        placeholder: 'Address 2',
      },
      {
        type: 'Input',
        key: 'city',
        title: 'City',
        dataType: 'String',
        value: '',
        placeholder: 'City',
        span: 4
      },
      {
        type: 'Input',
        key: 'state',
        title: 'State',
        dataType: 'String',
        value: '',
        placeholder: 'State',
        span: 4
      },
      {
        type: 'Input',
        key: 'postal_code',
        title: 'Postal Code',
        dataType: 'String',
        value: '',
        placeholder: 'Postal Code',
        span: 4
      },
      {
        type: 'Input',
        key: 'latitude',
        title: 'Latitude',
        dataType: 'String',
        value: '',
        placeholder: 'Latitude',
        span: 6
      },
      {
        type: 'Input',
        key: 'longtitude',
        title: 'Long',
        dataType: 'String',
        value: '',
        placeholder: 'Long',
        span: 6
      },
      {
        type: 'Input',
        key: 'marker_icon_url',
        title: 'Map Marker URL',
        dataType: 'String',
        value: '',
        placeholder: 'Long',
        span: 12
      },
      {
        type: 'Input',
        key: 'disabled_marker_icon_url',
        title: 'Disabled Map Marker URL',
        dataType: 'String',
        value: '',
        placeholder: 'Long',
        span: 12
      },
      {
        type: 'TagsPicker',
        key: 'tag_ids',
        title: 'Tags',
        dataType: '[ID]',
        value: '',
        placeholder: 'Tags'
      },
      {
        type: 'Checkbox',
        key: 'is_paid',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Is Paid?',
      },
      {
        type: 'Textarea',
        key: 'description',
        title: 'Description',
        dataType: 'String',
        value: '',
        placeholder: 'Description',
      },
      {
        type: 'Textarea',
        key: 'map_notes',
        title: 'Map Notes',
        dataType: 'String',
        value: '',
        placeholder: 'Map Notes',
      },
      {
        type: 'Textarea',
        key: 'parking_notes',
        title: 'Parking Notes',
        dataType: 'String',
        value: '',
        placeholder: 'Parking Notes',
      },
    ]
  },
  {
    name: 'Content Gamification',
    display: 'Content Gamification',
    __typename: 'ContentGamificationModule',
    id: 'content_gamification',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    addInHomeComponent: true,
    description: 'Content checklist that can be connected to various cards and content assets which are tracked by the system for analysis.',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {
        type: 'Input',
        key: 'sub_title',
        title: 'Sub Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
      {

        type: 'StepsGamification',
        key: 'steps',
        dataType: 'String',
        value: '[]',
        placeholder: '',
        preProcessor: d => {
          if (!d) return [];
          console.log('preProcessor d', d)
          let dArr = JSON.parse(d)
          return dArr
            ?.map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              delete el.is_expanded
              return el;
            });
        },
        afterProcessing: (d) => {
          return JSON.stringify(d);
        }
      },

      {
        type: 'Checkbox',
        key: 'has_steps_data_source',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Steps has data source?',
      },
      {
        type: 'ApiPathPicker',
        key: 'data_api_path_id',
        dataType: 'ID',
        title: 'Data Source Api Path',
        value: null,
        placeholder: 'Select api path',
        visibilityParameter: 'has_steps_data_source',
        isVisible: (value) => {
          return value === true
        }
      },
      {
        type: 'StashFunctionsPicker',
        key: 'data_stash_function_id',
        dataType: 'ID',
        title: 'Data Steps Stash Function',
        value: null,
        placeholder: 'Select stash function',
        visibilityParameter: 'has_steps_data_source',
        isVisible: (value) => {
          return value === true
        }
      },
      {
        type: 'CardPicker',
        key: 'data_source_card_id',
        dataType: 'ID',
        title: 'Data Source Steps Card',
        value: null,
        placeholder: 'Select Data Source Step Card',
        visibilityParameter: 'has_steps_data_source',
        isVisible: (value) => {
          return value === true
        }
      },

      {
        type: 'Checkbox',
        key: 'connected_to_external_api',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Connected to external api?',
      },
      {
        type: 'ApiPathPicker',
        key: 'api_path_id',
        dataType: 'ID',
        title: 'Api Path',
        value: null,
        placeholder: 'Select api path',
        visibilityParameter: 'connected_to_external_api',
        isVisible: (value) => {
          return value === true
        }
      },
      {
        type: 'Checkbox',
        key: 'check_step_range_by_function',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Check Steps range by stash function',
      },
      {
        type: 'StashFunctionsPicker',
        key: 'steps_in_range_function_id',
        dataType: 'ID',
        title: 'Stash Function',
        value: null,
        placeholder: 'Select stash function',
        visibilityParameter: 'check_step_range_by_function',
        isVisible: (value) => {
          return value === true
        }
      }
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Content Tracking',
    display: 'Content Tracking',
    id: 'button_20',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_7',
    __typename: 'ContentTrackingModule',
    addInNewCommunication: false,
    addInDataConnection: true,
    addInHomeComponent: false,
    // iconName: 'link',
    builderIcon: svgIcons.DashboardDeckIcon,
    customIcon: svgIcons.InsertLinkDeckIcon,
    inputs: [
      {
        type: 'Input',
        key: 'button_title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'tracking_name',
        dataType: 'String',
        title: 'Tracking Name',
        value: undefined,
        placeholder: 'Tracking Name',
      },
      {
        type: 'Input',
        key: 'tracking_id',
        dataType: 'String',
        title: 'Tracking Id',
        disable: true,
        value: Math.random().toString(36).slice(2),
        placeholder: 'Tracking Id',
      },
    ],
  },
  {
    name: 'Form Tracking',
    display: 'Form Tracking',
    id: 'button_21',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_7',
    __typename: 'FormTrackingModule',
    addInNewCommunication: false,
    addInDataConnection: true,
    addInHomeComponent: false,
    // iconName: 'link',

    inputs: [
      {
        type: 'Input',
        key: 'tracking_name',
        dataType: 'String',
        title: 'Tracking Name',
        value: undefined,
        placeholder: 'Tracking Name',
      },
      {
        type: 'Input',
        key: 'tracking_id',
        dataType: 'String',
        title: 'Tracking Id',
        disable: true,
        value: Math.random().toString(36).slice(2),
        placeholder: 'Tracking Id',
      },
    ],
  },
  {
    name: 'Impression Tracking',
    display: 'Impression Tracking',
    id: 'button_22',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_7',
    __typename: 'ImpressionTrackingModule',
    addInNewCommunication: false,
    addInDataConnection: true,
    addInHomeComponent: false,
    // iconName: 'link',

    inputs: [
      {
        type: 'Input',
        key: 'tracking_name',
        dataType: 'String',
        title: 'Tracking Name',
        value: undefined,
        placeholder: 'Tracking Name',
      },
      {
        type: 'Input',
        key: 'tracking_id',
        dataType: 'String',
        title: 'Tracking Id',
        disable: true,
        value: Math.random().toString(36).slice(2),
        placeholder: 'Tracking Id',
      },
    ],
  },
  {
    name: 'Multimedia Component',
    display: 'Multimedia Component',
    id: 'multimedia_option_id',
    // parent: 'option_2',
    header: 'header_1',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    addInHomeComponent: true,
    hideFromCardBack: true,
    __typename: 'MultimediaCardModule',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'Textarea',
        key: 'description',
        title: 'Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'Textarea',
        key: 'body',
        title: 'Body',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'FilePicker',
        key: 'file_data',
        dataType: 'String',
        title: 'Media File',
        value: '',
        placeholder: 'Drag files here',
        accept: "image/*",
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
      },
      {
        type: 'Checkbox',
        key: 'is_text',
        dataType: 'Boolean',
        title: 'Is Text',
        value: false,
        placeholder: 'Is Text?',
      },
      {
        type: 'Checkbox',
        key: 'is_photo',
        dataType: 'Boolean',
        title: 'Is Photo',
        value: false,
        placeholder: 'Is Photo?',
      },
      {
        type: 'Checkbox',
        key: 'is_video',
        dataType: 'Boolean',
        title: 'Is Video',
        value: false,
        placeholder: 'Is Video?',
      }

    ],
    builderIcon: svgIcons.BlackVideoPlaylistIcon
  },
  {
    name: 'Investment',
    display: 'Investment',
    id: 'Investment_option_id',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    __typename: 'InvestmentCardModule',
    inputs: [
      {
        type: 'Input',
        key: 'name',
        title: 'Name',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'Textarea',
        key: 'intro',
        title: 'Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      // {
      //   type: 'Input',
      //   key: 'intro_button_text',
      //   title: 'Intro Button Text',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write title here!',
      //   maxLength: 125
      // },
      // {
      //   type: 'Input',
      //   key: 'form_button_text',
      //   title: 'Form Button Text',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write title here!',
      //   maxLength: 125
      // },
      // {
      //   type: 'Input',
      //   key: 'form_title',
      //   title: 'Form Title',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: 'Write title here!',
      //   maxLength: 125
      // },
      // {
      //   type: 'Textarea',
      //   key: 'form_description',
      //   title: 'Form Description',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: '',
      // },
      {
        type: 'Select',
        key: 'form_type',
        title: 'Form Type',
        dataType: 'String',
        placeholder: 'Form Type',
        options: [
          { label: 'Company Name & Amount', id: 'name_amount' }
        ]
      },
      {
        type: 'FilePicker',
        key: 'logo_data',
        dataType: 'String',
        title: 'Logo',
        value: '',
        placeholder: 'Drag files here',
        accept: "image/*",
        mutation: {
          key: 'logo',
          dataType: 'Upload',
        },
      },
    ]
  },
  {
    name: 'Card Stream',
    display: 'Card Stream',
    id: 'card_stream',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    __typename: 'CardStreamCardModule',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'Select',
        key: 'layout',
        title: 'Layout',
        dataType: 'String',
        value: '',
        placeholder: '',
        options: [
          { label: 'Vertical', id: 'vertical' },
          { label: 'Horizontal', id: 'horizontal' }
        ]
      },
      {
        type: 'Input',
        key: 'columns',
        title: 'Columns',
        dataType: 'String',
        value: '',
        placeholder: 'no of columns',
        maxLength: 125
      },
      {
        type: 'CardPicker',
        key: 'card_template_id',
        dataType: 'ID',
        title: 'Template ',
        value: '',
        placeholder: 'Select Template',
      }
    ]
  },
  {
    name: 'App Tour',
    display: 'App Tour',
    id: 'app_tour',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    __typename: 'AppTourCardModule',
    inputs: [
    //   title: String
    // description: String
    // cover_image_data: String
    // image_data: String
    // video_url: String
    // video_cover_photo_data: String
    // button_text: String
    // is_video: Boolean
    // style_override: String
      {
        type: 'Input',
        key: 'title',
        title: 'Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'Textarea',
        key: 'description',
        title: 'Playlist Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'FilePicker',
        key: 'image_data',
        title: 'Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'Input',
        key: 'video_url',
        title: 'Video URL',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'FilePicker',
        key: 'video_cover_photo_data',
        title: 'Video Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'video_cover_photo',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'Input',
        key: 'button_text',
        title: 'Button Text',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'Checkbox',
        key: 'is_video',
        dataType: 'Boolean',
        title: 'Is Video',
        value: false,
        placeholder: 'Is Video?',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style_override',
        element_type: 'json',
        element_type: 'json',
        title: 'Style Overrides',
        dataType: 'String',
        value: '',
        placeholder: 'Write style override here!',
      }
    ]
  },
  // {
  //   name: 'Vehicle Category',
  //   display: 'Vehicle Category',
  //   id: 'vehicle_category',
  //   icon: <Plus></Plus>,
  //   bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
  //   parent: 'option_4',
  //   builderIcon: svgIcons.BlackMeetingIcon,
  //   customIcon: svgIcons.BlackMeetingIcon,
  //   addInHomeComponent: true,
  //   __typename: 'VehicleCategoryCardModule',
  //   description: 'Add custom vehicle categories',
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       dataType: 'String',
  //       title: 'Title',
  //       value: '',
  //       placeholder: 'Enter Title',
  //     },
  //     {
  //       type: 'FilePicker',
  //       key: 'cover_image_data',
  //       dataType: 'String',
  //       title: 'Cover Image',
  //       value: '',
  //       placeholder: 'Drag files here',
  //       accept: "image/*",
  //       mutation: {
  //         key: 'cover_image',
  //         dataType: 'Upload',
  //       },
  //     },
  //     {
  //       type: 'BaseUiTextArea',
  //       key: 'style_override',
  //       element_type: 'json',
  //       title: 'Style Overrides',
  //       dataType: 'String',
  //       value: '',
  //       placeholder: 'Write style override here!',
  //     }
  //   ]
  // }
];

export const editorBtnMainOptions = [
  {
    name: 'Quiz',
    display: 'Quiz',
    id: 'quiz',
    addOnlyInQuiz: true,
    icon: (
      <StyledIcon
        src={svgIcons.AdvancedIcon}
      />
    ),
  },
  {
    name: 'Text',
    display: 'Text',
    id: 'option_1',
    icon: (
      <StyledIcon
        src={svgIcons.TextIcon}
      />
      // <svg
      //   width="20"
      //   height="15"
      //   viewBox="0 0 20 15"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path
      //     d="M0.5 0V3H5.5V15H8.5V3H13.5V0H0.5ZM19.5 5H10.5V8H13.5V15H16.5V8H19.5V5Z"
      //     fill="white"
      //   />
      // </svg>
    ),
  },
  {
    name: 'Media',
    display: 'Media',
    id: 'option_2',
    icon: (
      <StyledIcon
        src={svgIcons.MediaIcon}
      />
    ),
  },
  // {
  //   name: 'Media',
  //   display: 'Media',
  //   id: 'option_3',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="20"
  //       viewBox="0 0 24 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M2 4H0V9H0.01L0 18C0 19.1 0.9 20 2 20H20V18H2V4ZM22 2H14L12 0H6C4.9 0 4.01 0.9 4.01 2L4 14C4 15.1 4.9 16 6 16H22C23.1 16 24 15.1 24 14V4C24 2.9 23.1 2 22 2ZM7 13L11.5 7L15 11.51L17.5 8.5L21 13H7Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Event',
  //   display: 'Event',
  //   id: 'option_4',
  //   icon: (
  //     <svg
  //       width="21"
  //       height="22"
  //       viewBox="0 0 21 22"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M18.5 2H17.5V0H15.5V2H5.5V0H3.5V2H2.5C1.4 2 0.5 2.9 0.5 4V20C0.5 21.1 1.4 22 2.5 22H18.5C19.6 22 20.5 21.1 20.5 20V4C20.5 2.9 19.6 2 18.5 2ZM18.5 20H2.5V7H18.5V20Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'Buttons',
    display: 'Buttons',
    id: 'option_3',
    icon: (
      <StyledIcon
        src={svgIcons.ButtonsIcon}
      />
    ),
  },
  // {
  //   name: 'Web',
  //   display: 'Web',
  //   id: 'option_5',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="20"
  //       viewBox="0 0 24 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M2 4H0V9H0.01L0 18C0 19.1 0.9 20 2 20H20V18H2V4ZM22 2H14L12 0H6C4.9 0 4.01 0.9 4.01 2L4 14C4 15.1 4.9 16 6 16H22C23.1 16 24 15.1 24 14V4C24 2.9 23.1 2 22 2ZM7 13L11.5 7L15 11.51L17.5 8.5L21 13H7Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Contact',
  //   display: 'Contact',
  //   id: 'option_6',
  //   icon: (
  //     <svg
  //       width="18"
  //       height="20"
  //       viewBox="0 0 18 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0 2.9 0 4V18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 5C10.66 5 12 6.34 12 8C12 9.66 10.66 11 9 11C7.34 11 6 9.66 6 8C6 6.34 7.34 5 9 5ZM15 17H3V16C3 14 7 12.9 9 12.9C11 12.9 15 14 15 16V17Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'Advanced',
    display: 'Advanced',
    id: 'option_4',
    icon: (
      <StyledIcon
        src={svgIcons.AdvancedIcon}
      />
    ),
  },
  {
    name: 'More',
    display: 'More',
    id: 'option_5',
    icon: (
      <StyledIcon
        src={svgIcons.MoreIcon}
      />
    ),
  },
  {
    name: 'Card Meta',
    display: 'Card Meta',
    id: 'option_6',
    icon: (
      <StyledIcon
        src={svgIcons.SwitchAccessWhite}
      />
    ),
  },
  {
    name: 'Tracking',
    display: 'Tracking',
    id: 'option_7',
    icon: (
      <StyledIcon
        src={svgIcons.TrackingIcon}
      />
    ),
  },
];
